import { ChangeEvent, useState } from 'react';
import { AffectedPart, HorseLameness } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import LimitedTextArea from '../LimitedTextArea';
import BasicModal from './BasicModal';
import { Box, Button, MenuItem, TextField } from '@mui/material';

function UpdateLamenessModal(props: {
  horse: string;
  horseName: string;
  lameness: HorseLameness;
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);
  const [horseIsStillLame, setHorseIsStillLame] = useState('');
  const [lameness, setLameness] = useState<HorseLameness>(props.lameness);

  async function handleSubmit() {
    setButtonsDisabled(true);
    setValidated(true);
    setErrorMessage('');

    const form: any = document.getElementById('lamenessForm');

    try {
      if (form && form.checkValidity()) {
        if (lameness.notes && lameness.notes.length > 1000) {
          setErrorMessage('Comments section is too long. Please check and reduce the size.');
          setButtonsDisabled(false);
          return;
        }

        const result = await HttpClient.put(`/horses/${props.horse}/lameness/${lameness.id}`, {
          ...lameness,
          horseName: props.horseName,
          endDate: lameness.endDate === 'Ongoing' ? undefined : lameness.endDate,
        });

        props.handleConfirm();
        props.handleClose();
      } else {
        setErrorMessage(
          `Please enter if the horse is still lame and a ${
            horseIsStillLame === 'Yes' ? 'recheck' : 'end'
          } date.`
        );
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  }

  return (
    <BasicModal
      onClose={props.handleClose}
      open={props.show}
      title='Update Lameness'
      submitButton={{
        color: 'success',
        text: 'Update',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error adding lameness' errorMessage={errorMessage} />
      <form id='lamenessForm'>
        <Box marginBottom={'1em'}>
          <TextField
            fullWidth
            select
            required
            label='Is the horse still lame?'
            onChange={(e) => {
              setHorseIsStillLame(e.target.value);
            }}
            value={horseIsStillLame}
            name='horseIsStillLame'
            error={!horseIsStillLame}
            helperText={
              !horseIsStillLame ? 'Please choose select whether the horse is lame or not.' : ''
            }
          >
            <MenuItem value={'Yes'}>Yes</MenuItem>
            <MenuItem value={'No'}>No</MenuItem>
          </TextField>
        </Box>
        {horseIsStillLame != '' ? (
          <>
            <Box marginBottom={'1em'}>
              <TextField
                fullWidth
                label={horseIsStillLame == 'Yes' ? 'Recheck Date' : 'End Date'}
                required
                type='date'
                onChange={(e) => {
                  const fieldName = horseIsStillLame == 'Yes' ? 'checkDate' : 'endDate';
                  setLameness((prevState) => ({
                    ...prevState,
                    [fieldName]: e.target.value,
                  }));
                }}
                value={
                  horseIsStillLame == 'Yes'
                    ? lameness.checkDate
                    : lameness.endDate !== 'Ongoing'
                    ? lameness.endDate
                    : ''
                }
                name='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box marginBottom={'1em'}>
              <LimitedTextArea
                maxChars={1000}
                label='Comments'
                data={lameness.notes ? lameness.notes : ''}
                setData={(e: ChangeEvent<HTMLInputElement>) => {
                  setLameness((prevState) => ({
                    ...prevState,
                    notes: e.target.value,
                  }));
                }}
              />
            </Box>
          </>
        ) : null}
      </form>
    </BasicModal>
  );
}

export default UpdateLamenessModal;
