import { Day } from './Day';

export enum PersonType {
  None = '',
  Rider = 'Rider',
  Volunteer = 'Volunteer',
  Both = 'Both',
}

type Id = {
  id: string;
};

export type PersonToAdd = {
  firstName: string;
  lastName: string;
  startDate?: string;
  rider: boolean;
  staff: boolean;
  eval: boolean;
  schooling: boolean;
  volunteer: boolean;
  volunteerLevel: number | string;
  emergencyContacts?: Contact[];
};

export type Person = Id & PersonToAdd;

export type RiderLesson = {
  level?: number;
  lessonTime?: string;
  lessonDay?: Day;
  lessonId?: number;
  lessonStartDate?: string;
  lessonEndDate?: string;
  leftClass?: string;
  currentLesson?: boolean;
};

export type RiderList = Person & RiderLesson;

export type RiderAndLesson = Person & {
  lessonInfo: {
    currentLessons: RiderLesson[];
    pastLessons: RiderLesson[];
    futureLessons: RiderLesson[];
  };
};

// Riders that are added to Lessons that don't normally ride there
export type AddedRider = {
  addedId: string;
  id: string;
  lessonId: number;
  schooling: boolean;
  horse: string;
  firstName: string;
  lastName: string;
};

export type RiderAbsence = {
  id?: string;
  rider: string;
  lesson: number;
  riderName: string;
  absentDate: string;
  makeupDate?: string;
  makeupLesson?: number | string;
  makeupLessonTime: string;
  source?: string;
};

export type VolunteerHour = {
  id: string;
  date?: string;
  volunteer: string;
  duration: number;
  hours?: string;
  mins?: string;
  firstName: string;
  lastName: string;
};

export type SubmitVolunteerHour = {
  id?: string;
  date: string;
  volunteer: string;
  duration: number;
};

export type VolunteerHourResult = {
  volunteer: string;
  duration: number;
  volunteerLevel: number;
  firstName: string;
  lastName: string;
};

export type VolunteerHourAndName = SubmitVolunteerHour & {
  firstName: string;
  lastName: string;
};

export type Contact = {
  id?: string;
  type: '' | 'EmergencyContact';
  relationship: '' | 'Parent' | 'Grandparent' | 'Spouse' | 'Friend' | 'Other';
  firstName: string;
  lastName: string;
  phoneNumber: string;
};
