import { formatTime } from '../../helpers/utils';
import { RiderAbsence } from '../../types/People';
import DeleteIcon from '@mui/icons-material/Delete';
import { Lesson } from '../../types/Lessons';
import { Autocomplete, Button, FormHelperText, Grid, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Day } from '../../pages/Board/DayView';
import { HttpClient } from '../../helpers/httpClient';
import dayjs from 'dayjs';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function AbsentRiderRow(props: {
  lessons: Lesson[];
  absence: RiderAbsence & { error: boolean };
  updateAbsence: Function;
  setErrorMessage: Function;
}) {
  const [board, setBoard] = useState<Day>([]);
  const [selectedRider, setSelectedRider] = useState<{
    id: string;
    name: string;
  } | null>(null);

  async function getBoard(date: string) {
    const absentDate = date ? dayjs(date) : dayjs();
    try {
      const result: Day = await HttpClient.get(
        `/board/day/${daysOfWeek[absentDate.day()]}`,
        {},
        { date: absentDate.format('YYYY-MM-DD') }
      );
      setBoard(result);
    } catch (error) {
      props.setErrorMessage('Could not load available riders. Please contact Rebecca.');
    }
  }

  useEffect(() => {
    getBoard(props.absence.absentDate);
  }, [props.absence.absentDate]);

  const riders = [];
  for (let i = 0; i < board.length; i++) {
    for (let j = 0; j < board[i].entries.length; j++) {
      riders.push({
        riderName: board[i].entries[j].personName,
        id: board[i].entries[j].personId,
        lesson: board[i].lessonId,
        lessonTime: formatTime(board[i].lessonTime),
      });
    }
  }

  return (
    <Grid container spacing={2} marginTop='0.5em'>
      <Grid item xs={12} sm={12}>
        <TextField
          label='Absent Date'
          required
          type='date'
          onChange={(event) => {
            props.updateAbsence({
              ...props.absence,
              absentDate: event.target.value,
            });
          }}
          value={props.absence.absentDate}
          name='absentDate'
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Lesson Time'
          select
          required
          aria-label='Lesson Time'
          onChange={(event) => {
            let lesson = board.find((lesson) => lesson.lessonId === Number(event.target.value));
            props.updateAbsence({
              ...props.absence,
              lesson: Number(event.target.value),
              lessonTime: lesson?.lessonTime ? formatTime(lesson.lessonTime) : '',
            });
          }}
          value={props.absence.lesson ? props.absence.lesson : ''}
          name='lesson'
          disabled={!props.absence.absentDate}
          helperText={
            !props.absence.absentDate ? 'Select an absent date to select the lessons time.' : ''
          }
          fullWidth
        >
          {board.map((lesson) => (
            <MenuItem key={`${lesson.lessonId}-option`} value={lesson.lessonId}>
              {lesson.lessonTime ? formatTime(lesson.lessonTime) : ''}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          disabled={!props.absence.lesson || props.absence.lesson < 0}
          disablePortal
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          value={selectedRider}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={
            board
              .find((lesson) => lesson.lessonId === props.absence.lesson)
              ?.entries.map((rider) => {
                return {
                  id: rider.personId,
                  name: rider.personName,
                };
              }) ?? []
          }
          renderInput={(params) => <TextField {...params} label='Rider' />}
          onChange={(event, selectedOption) => {
            setSelectedRider(selectedOption);
            props.updateAbsence({
              ...props.absence,
              rider: selectedOption ? selectedOption.id : '',
              riderName: selectedOption ? selectedOption.name : '',
            });
          }}
        />
        <FormHelperText>
          {!props.absence.lesson || props.absence.lesson < 0
            ? 'Select a lesson time to be able to select a rider'
            : ''}
        </FormHelperText>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Make Up Class Date'
          type='date'
          onChange={(event) => {
            props.updateAbsence({
              ...props.absence,
              makeupDate: event.target.value,
              makeupLesson: '',
              makeupLessonTime: '',
            });
          }}
          value={props.absence.makeupDate}
          name='makeupDate'
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          helperText='Add a makeup lesson date if they are making up the lesson.'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Lesson Time'
          select
          aria-label='Lesson Time'
          onChange={(event) => {
            const parts = event.target.value.split('@');

            props.updateAbsence({
              ...props.absence,
              makeupLesson: Number(parts[0]),
              makeupLessonTime: parts[1],
            });
          }}
          value={
            props.absence.makeupLesson && props.absence.makeupLessonTime
              ? `${props.absence.makeupLesson}@${props.absence.makeupLessonTime}`
              : ''
          }
          name='makeupLesson'
          disabled={!props.absence.makeupDate}
          helperText={
            !props.absence.makeupDate ? 'Select a make up date to select the lessons time.' : ''
          }
          fullWidth
        >
          <MenuItem value={-1}></MenuItem>
          {props.lessons
            .filter((l) => {
              if (!props.absence.makeupDate) {
                return false;
              }
              return daysOfWeek[new Date(props.absence.makeupDate).getUTCDay()] === l.lessonDay;
            })
            .map((lesson) => (
              <MenuItem
                key={`${lesson.id}-option`}
                value={`${lesson.id}@${formatTime(lesson.lessonTime)}`}
              >
                {lesson.lessonTime ? formatTime(lesson.lessonTime) : ''}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} alignContent={'center'}>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            props.updateAbsence();
          }}
          fullWidth
        >
          <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
        </Button>
      </Grid>
    </Grid>
  );
}

export default AbsentRiderRow;
